<template>
	<TheHeader></TheHeader>
	<div id="container" class="container container--fit">
		<div id="sub" class="sub inn">
			<!-- category -->
			<section class="category">
				<div class="box">
					<ul class="category__list">
						<!-- <router-link v-for="item in typeList" :key="item.code" :to="`/layers/location?callbackUrl=/rooms/list?hotel_type=${item.code}`" custom v-slot="{ navigate }"> -->
						<li>
							<router-link :to="'/layers/location?callbackUrl=/rooms/list?hotel_type=HO'">
								<img src="@/assets/images/icon/category_h.png" alt="호텔" style="vertical-align:middle;height:40px;">
								<span>{{t('10022')}}</span>
							</router-link>
						</li>
						<li>
							<router-link :to="'/layers/location?callbackUrl=/rooms/list?hotel_type=MO'">
								<img src="@/assets/images/icon/category_m.png" alt="모텔" style="vertical-align:middle;height:40px;">
								<span>{{t('10021')}}</span>
							</router-link>
						</li>
						<li>
							<router-link :to="'/layers/location?callbackUrl=/rooms/list?hotel_type=RE'">
								<img src="@/assets/images/icon/category_p.png" alt="펜션·리조트" style="vertical-align:middle;height:40px;">
								<span>{{t('10024')}}</span>
							</router-link>
						</li>
						<li>
							<router-link :to="'/layers/location?callbackUrl=/rooms/list?hotel_type=TO'">
								<img src="@/assets/images/icon/category_g.png" alt="게하·한옥" style="vertical-align:middle;height:40px;">
								<span>{{t('10563')}}</span>
							</router-link>
						</li>
						<li>
							<router-link :to="'/layers/location?callbackUrl=/rooms/list?hotel_type=MO'">
								<img src="@/assets/images/icon/category_l.png" alt="글램핑" style="vertical-align:middle;height:40px;">
								<span>{{t('10564')}}</span>
							</router-link>
						</li>
					</ul>
				</div>
			</section>
			<!-- content -->
			<section class="content">
				<!-- 이벤트 -->
				<div class="item" v-if="eventList.length > 0">
					<div class="banner bg">
						<!-- [D] 뱃지 있을 시 추가 -->
						<div class="banner__badge badge badge--notice">
							<span class="badge__text">
								{{t('10062')}}
							</span>
						</div>
						<!-- [REST] 이벤트 노출, 관리자에서 설정 -->
						<div class="banner__slider" >
							<swiper :scrollbar="{ el : '.progress__bar--2' }">
								<swiper-slide v-for="item in eventList" :key="item.PROMO_NO">
									<router-link :to="'/event/' + item.PROMO_NO" class="banner__link">
										<div class="banner__image noneimage">
											<div class="imagebg" :style="{'background-image': 'url(\'' + item.IMG_URL2 + '\')'}" />
										</div>
										<div class="banner__info">
											<h3 class="banner__title">
												{{item.PROMO_INTRO}}
											</h3>
											<p class="banner__desc">
												{{item.PROMO_DESC}}
											</p>
										</div>
									</router-link>
								</swiper-slide>
								<div class="progress" v-if="eventList.length > 1">
									<div class="progress__bar progress__bar--2"></div>
								</div>
							</swiper>
						</div>
					</div>
				</div>
				<!-- 헬로스테이 추천하는 주변 모텔 -->
				<div class="item" v-if="operatorRecommend.length > 0">
					<h2 class="title">
						{{t('10565')}} <!-- 재번역 체크 -->
					</h2>
					<div class="item__body">
						<div class="carousel">
							<!-- [REST] 현재 기준 가까운 상품 10개 호출 -->
							<div class="carousel__slider" >
								<swiper :slides-per-view="1.4" :space-between="16">
									<swiper-slide v-for="item in operatorRecommend" :key="item.hotel_nm">
										<router-link :to="`/rooms/${item.hotel_no}`" class="carousel__link">
											<div class="carousel__image noneimage">
												<div class="imagebg" :style="{'background-image': 'url(\'' + item.img_url + '\')'}" />
											</div>
											<div class="carousel__info">
												<div class="carousel__slogan info-slogan">
													{{item.hotel_desc}}
												</div>
												<h3 class="carousel__title info-title">
													{{item.hotel_nm}}
												</h3>
												<div class="carousel__grad info-grad"  v-if="item.reviews > 0">
														<span class="carousel__icon info-icon">
															<i class="icon icon-star"></i>
														</span>
													<span class="carousel__gradnum info-gradnum">
														{{item.score.toFixed(1)}}
													</span>
													<span class="carousel__gradcounter info-gradcounter">
														({{item.reviews}})
													</span>
												</div>
												<div class="carousel__price">
													<!-- <span class="carousel__day">
														1박기준
													</span> -->
													<span class="carousel__saleprice" v-if="item.DCRATE_NIGHT > 0">
														{{item.DCRATE_NIGHT}}%
													</span>
													<span class="carousel__currentprice">
														<strong>{{item.SALESAMT_NIGHT.toLocaleString("en-US")}}</strong>원
													</span>
												</div>
											</div>
										</router-link>
									</swiper-slide>
								</swiper>
							</div>
						</div>
					</div>
				</div>
				<!-- 지금 인기 있는 숙박 -->
				<div class="item">
					<h2 class="title">
						{{t('10566')}} 
					</h2>
					<div class="item__body">
						<!-- [REST] 인기 숙박 노출, 최근 7일 이내 예약 많은 순 노출 (최대 10개) -->
						<div class="carousel" v-if="popularRecommend.length > 0">
							<swiper :slides-per-view="1.4" :space-between="16">
								<swiper-slide v-for="item in popularRecommend" :key="item.hotel_nm">
									<router-link :to="`/rooms/${item.hotel_no}`" class="carousel__link">
										<div class="carousel__image noneimage">
											<div class="imagebg" :style="{'background-image': 'url(\'' + item.img_url + '\')'}" />
										</div>
										<div class="carousel__info">
											<div class="carousel__slogan info-slogan">
												{{item.hotel_desc}}
											</div>
											<h3 class="carousel__title info-title">
												{{item.hotel_nm}}
											</h3>
											<div class="carousel__grad info-grad" v-if="item.reviews > 0">
												<span class="carousel__icon info-icon">
													<i class="icon icon-star"></i>
												</span>
												<span class="carousel__gradnum info-gradnum">
													{{item.score.toFixed(1)}}
												</span>
												<span class="carousel__gradcounter info-gradcounter">
												  ({{item.reviews}})
												</span>
											</div>
											<div class="carousel__price">
												<!-- <span class="carousel__day">
													1박기준
												</span> -->
													<span class="carousel__saleprice" v-if="item.DCRATE_NIGHT > 0">
												  		{{item.DCRATE_NIGHT}}%
													</span>
												<span class="carousel__currentprice">
														<strong>{{item.SALESAMT_NIGHT.toLocaleString("en-US")}}</strong>원
													</span>
											</div>
										</div>
									</router-link>
								</swiper-slide>
							</swiper>
						</div>
						
					</div>
				</div>
				<!-- 지역별 추천 숙소 -->
				<div class="item">
					<h2 class="title">
						{{t('10567')}}
					</h2>
					<div class="item__body">
						<div class="menus menus--small">
							<div class="menus__wrap">
								<!-- [D] 활성화된 지역에 active 클래스 추가 -->
								<!-- [REST] 지역 분류 관리자 페이지에서 설정 -->
								<div class="menus__box">
									<div 
										v-for="item in mhm0103" 
										:key="item.AREA_CD2" 
										class="menus__text"
										:class="{'active' : areaActive === item.AREA_CD2}"
										@click="onClickArea(item)">
											{{item.LOC_NM}}
									</div>
								</div>
							</div>
						</div>
						<div class="carousel carousel--left">
							<!-- [REST] 지역별 숙소 호출 -->
							<div class="carousel__slider" data-slider="carousel">
								<swiper :slides-per-view="1.4" :space-between="16">
									<swiper-slide v-for="item in locationRecommend[areaActive]" :key="item.hotel_nm">
										<router-link :to="`/rooms/${item.hotel_no}`" class="carousel__link">
											<div class="carousel__image noneimage">
												<div class="imagebg" :style="{'background-image': 'url(\'' + item.img_url + '\')'}" />
											</div>
											<div class="carousel__info">
												<div class="carousel__slogan info-slogan">
													{{item.hotel_desc}}
												</div>
												<h3 class="carousel__title info-title">
													{{item.hotel_nm}}
												</h3>
												<div class="carousel__grad info-grad"  v-if="item.reviews > 0">
														<span class="carousel__icon info-icon">
															<i class="icon icon-star"></i>
														</span>
													<span class="carousel__gradnum info-gradnum">
														{{item.score.toFixed(1)}}
													</span>
													<span class="carousel__gradcounter info-gradcounter">
														({{item.reviews}})
													</span>
												</div>
												<div class="carousel__price">
														<!-- <span class="carousel__day">
															1박기준
														</span> -->
													<span class="carousel__saleprice"  v-if="item.DCRATE_NIGHT > 0">
														{{item.DCRATE_NIGHT}}%
														</span>
													<span class="carousel__currentprice">
															<strong>{{item.SALESAMT_NIGHT.toLocaleString("en-US")}}</strong>원
														</span>
												</div>
											</div>
										</router-link>
									</swiper-slide>
								</swiper>
							</div>
						</div>
					</div>
				</div>
				<!-- 헬로스테이 기획전 -->
				<div class="item">
					<h2 class="title">
						{{t('10204')}}
						<router-link to="/event?type=1" class="btn-more">
							MORE
						</router-link>
					</h2>
					<div class="item__body">
						<div class="card">
							<!-- [D] 뱃지 있을 시 추가 -->
							<div class="card__badge badge badge--plan">
								<span class="badge__text">
									{{t('10204')}}
								</span>
							</div>
							<!-- [REST] 기획전 노출, 관리자 페이지에서 설정 -->
							<div class="card__slider" v-if="planList.length > 0">
								<swiper :scrollbar="{ el : '.progress__bar--1' }">
									<swiper-slide  v-for="item in planList" :key="item.PROMO_NO">
										<router-link :to="'/event/' + item.PROMO_NO" class="card__link">
											<div class="card__image">
												<div class="imagebg" :style="{'background-image': 'url(\'' + item.IMG_URL2 + '\')'}" />
											</div>
										</router-link>
									</swiper-slide>
								</swiper>
							</div>
							<div class="progress" v-if="planList.length > 1">
								<div class="progress__bar progress__bar--1"></div>
							</div>
						</div>
					</div>
				</div>
				<!-- 가을 추천 펜션 베스트 5 -->
				<div class="item" v-if="mrm0104.length > 0">
					<!-- [REST] 관리자 페이지에서 타이틀 설정 -->
					<h2 class="title">
						{{mrm0104[0].PROMO_NM}}
					</h2>
					<div class="item__body">
						<div class="carousel">
							<!-- [REST] 노출상품 관리자 페이지에서 설정 -->
							<div class="carousel__slider">
								<swiper :slides-per-view="1.4" :space-between="16">
									<swiper-slide v-for="item in mrm0104" :key="item.HOTEL_NM">
										<router-link :to="`/rooms/${item.HOTEL_NO}`" class="carousel__link">
											<div class="carousel__image noneimage">
												<div class="imagebg" :style="{'background-image': 'url(\'' + item.IMG_URL + '\')'}" />
											</div>
											<div class="carousel__info">
												<div class="carousel__slogan info-slogan">
													{{item.hotel_desc}}
												</div>
												<h3 class="carousel__title info-title">
													{{item.HOTEL_NM}}
												</h3>
												<div class="carousel__grad info-grad"  v-if="item.reviews > 0">
														<span class="carousel__icon info-icon">
															<i class="icon icon-star"></i>
														</span>
													<span class="carousel__gradnum info-gradnum">
														{{item.SCORE.toFixed(1)}}
													</span>
													<span class="carousel__gradcounter info-gradcounter">
														({{item.REVIEWS}})
													</span>
												</div>
												<div class="carousel__price">
													<!-- <span class="carousel__day">
														1박기준
													</span> -->
													<span class="carousel__saleprice" v-if="item.DCRATE_NIGHT > 0">
														{{item.DCRATE_NIGHT}}%
													</span>
													<span class="carousel__currentprice">
														<strong>{{item.SALESAMT_NIGHT.toLocaleString("en-US")}}</strong>원
													</span>
												</div>
												<!-- <div class="carousel__price">
														<span class="carousel__day">
															1박기준
														</span>
													<span class="carousel__saleprice">
														{{item.DCRATE_NIGHT}}%
														</span>
													<span class="carousel__currentprice">
															<strong>{{item.SALESAMT_NIGHT.toLocaleString("en-US")}}</strong>원
														</span>
												</div> -->
											</div>
										</router-link>
									</swiper-slide>
								</swiper>
							</div>
						</div>
					</div>
				</div>

				<!-- 헬로스테이 추천 플레이스 -->
				<div class="item" v-if="urlhost !== 'www.hellostay.co.kr'">
					<h2 class="title">
						{{t('10208')}} <!-- 재번역 체크 -->
					</h2>
					<div class="item__body">
						<router-link to="/feed" custom v-slot="{ navigate }">
							<div class="card" @click="navigate">
								<a href="#" class="card__link">
									<div class="card__image noneimage">
										<div class="imagebg" :style="{'background-image': 'url(' + require('@/assets/images/temp08.jpg') + ')'}">
										</div>
									</div>
									<h2 class="card__title">
										{{t('10209')}}
										<i class="icon icon-func"></i>
									</h2>
									<p class="card__description">
										{{t('10568')}} <!-- 재번역 체크 -->
									</p>
								</a>
							</div>
						</router-link>
					</div>
				</div>
			</section>
		</div>
	</div>
	<TheFooter></TheFooter>
	<TheNavigation></TheNavigation>
    <TheTop/>
</template>

<script>
import { useStore } from "vuex"
import { computed, onMounted, ref, watchEffect } from "vue"
import numberWithCommas from "@/utils/numberWithCommas";
import { useI18n } from 'vue-i18n' //번역필수 모듈
import i18n from "@/i18n"; 

export default {
	setup: function() {
		const store = useStore();
		const areaActive = ref("0100");
        const { t }= useI18n() //번역필수 모듈

		const operatorRecommend = computed(() => store.state.mrm01.operatorRecommend);
		const popularRecommend = computed(() => store.state.mrm01.popularRecommend);
		const locationRecommend = computed(() => store.state.mrm01.locationRecommend);

		const eventList = computed(() => store.state.mrm01.eventList);
		const planList = computed(() => store.state.mrm01.planList);

		const mrm0104 = computed(() => store.state.mrm01.mrm0104);
		const mhm0103 = computed(() => store.state.mhm01.mhm0103);

    	const mcd00 = computed(() => store.state.mcd00.mcd00);
		const typeList = computed(()=> (mcd00.value["R03"] ?? []));
    	const urlhost = ref();

		watchEffect(() => {
			if(store.state.app.location.lat !== null) {
				store.dispatch("mrm01/fetchOperatorRecommend", {
					lati: store.state.app.location.lat, 
					longi: store.state.app.location.lng, 
					area_cd: "0111" // 좌표로 지역 코드 가져오는 api 추가되면 고치기
				});
			}
		})
		onMounted(() => {
      		urlhost.value = store.state.app.urlhost;
			try {
				store.dispatch("app/requestLocation");
			} catch(e) {
				navigator.geolocation.getCurrentPosition(
					function(position) {
					var posi_lat = position.coords.latitude;
					var posi_longi = position.coords.longitude;
					store.commit("app/SET_LOCATION", {
						location: {
							lat: posi_lat,
							lng: posi_longi
						}
					});
					}, 
				);
			}
			store.dispatch("mrm01/fetchPopularRecommend");
			store.dispatch("mrm01/fetchEventList");
			store.dispatch("mrm01/fetchPlanList");
			store.dispatch("mrm01/fetchMrm0104");

			// 지역 분류 
			store.dispatch("mhm01/fetchMhm0103", {proc_cd: "01"}).then(x => {
				store.dispatch("mrm01/fetchLocationRecommend", {area_cd: x[0].AREA_CD2});
				areaActive.value = x[0].AREA_CD2;
			});

			// 아이콘 로드
      if(!("R03" in mcd00.value)){ 
        store.dispatch("mcd00/fetchMcd00", {
          code_grp: "R03"
        });
      }
		});

		const onClickArea = (data) => {
			areaActive.value = data.AREA_CD2;
			if(!(data.AREA_CD2 in locationRecommend.value)){
				store.dispatch("mrm01/fetchLocationRecommend", {area_cd: data.AREA_CD2});
			}
		}

		return {
			operatorRecommend, //mrm010201,
			popularRecommend, //popularRecommend,
			locationRecommend, //locationRecommend,
			eventList, //eventList,
			planList, //planList,
			mrm0104,
			mhm0103,
			onClickArea,
			areaActive,
			store,
			numberWithCommas,
			typeList,
			t,
			i18n,
      		urlhost
		}
	},
}
</script>


